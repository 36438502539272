import styled from "@emotion/styled/macro";
import { useMediaQuery } from "@mui/material";
import { DESKTOP_HEIGHT_WITH_HEADER_LINKS } from "react-app/src/components/Header/constants";
import { MQ } from "react-app/src/styles/helpers";
import spaces from "react-app/src/styles/theme/spaces";
import {
  fontSizeScale,
  fontSizes,
} from "react-app/src/styles/theme/typography";
import { componentMaxWidth } from "react-app/src/styles/xxl-theme";
import { mobileMediaQuery } from "react-app/src/utils/xxl-screen";

const DESKTOP_FONT_SIZE = fontSizeScale[6];

export const Wrapper = styled.div<{ color: string }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ color }) => color};
`;

export const InnerWrapper = styled.div<{ hasPadding: boolean }>`
  padding: ${({ hasPadding }) => (hasPadding ? spaces.large : 0)} 12px;
  box-sizing: border-box;
  width: 100%;
  max-width: ${componentMaxWidth}px;

  ${MQ("laptop")} {
    padding: ${({ hasPadding }) => (hasPadding ? spaces.almostHuge : 0)} 0;
  }
`;

export const HeaderWithHeadingOffset = styled.h2`
  scroll-margin: ${() =>
      `${
        useMediaQuery(mobileMediaQuery) ? 0 : DESKTOP_HEIGHT_WITH_HEADER_LINKS
      }px`}
    0 0;
  font-size: ${fontSizes.big}px;
  ${MQ("laptop")} {
    font-size: ${DESKTOP_FONT_SIZE}px;
  }
`;
