import React from "react";
import { Stack } from "@mui/material";
import { useTranslations } from "react-app/src/contexts/Translations/TranslationsContext";
import {
  DrawerLi,
  DrawerUl,
  OutOfStockText,
  OutOfStockWrapper,
} from "src/components/ProductDetailsPage/ProductSizeSelector/ProductSizeSelector.styled";
import { Drawer } from "react-app/src/components/Drawer/Drawer";
import { xxlTheme } from "react-app/src/styles/xxl-theme";
import type { SizeOption } from "./types";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onSizeSelect: (ean: string) => void;
  sizeOptions: SizeOption[];
};

export const AccessoriesSizeSelectDrawer = ({
  isOpen,
  onClose,
  sizeOptions,
  onSizeSelect,
}: Props) => {
  const { t } = useTranslations();

  const body = (
    <Stack spacing={0.5}>
      <DrawerUl>
        {sizeOptions.map((option) => {
          const { ean, size, isInStock } = option;
          const isOutOfStock = Object.values(isInStock).every(
            (value) => !value
          );

          return (
            <DrawerLi
              key={ean}
              data-testid={`size-${size}`}
              onClick={() => {
                if (isOutOfStock) {
                  return;
                }
                onClose();
                onSizeSelect(option.ean);
              }}
              aria-disabled={isOutOfStock}
              disabled={isOutOfStock}
              aria-label={
                isOutOfStock
                  ? `${t("product.details.storestock.out")}, ${t(
                      "general.remind.me"
                    )}`
                  : undefined
              }
            >
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <div>{size}</div>
                {isOutOfStock && (
                  <OutOfStockWrapper>
                    <OutOfStockText>
                      {t("general.available.online.none")}
                    </OutOfStockText>
                  </OutOfStockWrapper>
                )}
              </Stack>
            </DrawerLi>
          );
        })}
      </DrawerUl>
    </Stack>
  );

  return (
    <Drawer
      open={isOpen}
      onClose={onClose}
      heading={t("product.details.select.size")}
      customPadding={`0 ${xxlTheme.spaces.smallRegular}`}
    >
      {body}
    </Drawer>
  );
};
