import styled from "@emotion/styled/macro";
import { Stack } from "@mui/material";
import { xxlTheme } from "react-app/src/styles/xxl-theme";

const ContainerDesktop = styled.div`
  min-width: 440px;
  display: grid;
  place-items: center;
`;

const BANNER_HEIGHT = "35px";
const BANNER_OFFSET = xxlTheme.spaces.large;

const Banner = styled.div`
  position: relative;
  height: ${BANNER_HEIGHT};
  width: fit-content;
  padding: 0 ${xxlTheme.spaces.smallRegular};
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${xxlTheme.colors.xxlWebBlack};
  color: ${xxlTheme.colors.xxlWhite};
  top: calc(${BANNER_OFFSET} + ${BANNER_HEIGHT});
  left: -${BANNER_OFFSET};
`;

const ContainerMobile = styled.div`
  display: flex;
  justify-content: space-between;
  height: 134px;
  background-color: ${xxlTheme.colors.xxlLightGrey};
  overflow: hidden;
`;

const BannerMobile = styled.div`
  height: ${BANNER_HEIGHT};
  padding: 0 ${xxlTheme.spaces.smallRegular};
  display: grid;
  place-content: center;
  background-color: ${xxlTheme.colors.xxlWebBlack};
  color: ${xxlTheme.colors.xxlWhite};
  margin: ${xxlTheme.spaces.smallRegular};
  width: max-content;
`;

const ImageBackgroundMobile = styled.div`
  display: flex;
  justify-content: flex-end;

  background-color: ${xxlTheme.colors.xxlLightGrey};
  width: 100%;
  margin: ${xxlTheme.spaces.smallRegular};
`;

const ImageBackground = styled.div`
  display: grid;
  place-items: center;
  min-width: 266px;
  min-height: 386px;
  background-color: ${xxlTheme.colors.xxlLightGrey};
  z-index: -1;
`;

type Props = {
  text: string;
  isMobile?: boolean;
  children: React.ReactNode;
};
export const AccessoriesAside = ({
  text,
  isMobile = false,
  children,
}: Props) => {
  if (isMobile) {
    return (
      <ContainerMobile>
        <Stack>
          <BannerMobile> {text} </BannerMobile>
        </Stack>
        <ImageBackgroundMobile>{children}</ImageBackgroundMobile>
      </ContainerMobile>
    );
  }

  return (
    <ContainerDesktop>
      <div>
        <Banner> {text} </Banner>
        <ImageBackground>{children}</ImageBackground>
      </div>
    </ContainerDesktop>
  );
};
