import { useState } from "react";
import { SizeSelectButton } from "react-app/src/components/SizeSelectButton/SizeSelectButton";
import { useIsSizeSelectDrawerOpen } from "src/components/ProductDetailsPage/ProductDetailsPage.helper";
import { AccessoriesSizeSelectDrawer } from "./AccessoriesSizeSelectDrawer";
import { XxlButton } from "react-app/src/components/Common/XxlButton/XxlButton";
import { useTranslations } from "react-app/src/contexts/Translations/TranslationsContext";
import { QUANTITY_ONE } from "react-app/src/constants";
import { dispatchAddToCartEvent } from "react-app/src/utils/xxl-event";
import { useCartContext } from "react-app/src/components/Cart/CartState";
import type { CombinedProductData } from "react-app/src/components/Product/product-helper";
import { AddToCartWarning } from "src/components/ProductDetailsPage/AddToCartWarning";
import type { SizeOption } from "./types";
import { CLEAR_ERROR_TIMEOUT_MS } from "src/hooks/useAddToCartError";
import { isNotNullOrUndefined } from "@xxl/common-utils";
// eslint-disable-next-line import/no-extraneous-dependencies
import OutsideClickHandler from "react-outside-click-handler";
import { ButtonWrapper } from "./Accessories.styles";

type Props = {
  sizeOptions: SizeOption[];
  product: CombinedProductData;
};
export const SizeSelectContainer = (props: Props) => {
  const { sizeOptions, product } = props;

  const { t } = useTranslations();
  const [activeError, setActiveError] = useState(false);
  const [buyAfterSizeSelect, setBuyAfterSizeSelect] = useState(false);

  const [selectedOptionEan, setSelectedOptionEan] = useState<string>();

  const { isSizeSelectDrawerOpen, toggleIsSizeSelectDrawerOpen } =
    useIsSizeSelectDrawerOpen();

  const { state: cartState } = useCartContext();
  const { isAddingToCart } = cartState;

  const addToCart = (eanCode: string | undefined) => {
    if (eanCode === undefined) {
      return;
    }

    dispatchAddToCartEvent({
      brandName: product.brand?.name ?? undefined,
      categoryCode: product.code ?? "",
      eanCode,
      imageUrl: product.primaryImage ?? "",
      productName: product.name ?? "",
      quantity: QUANTITY_ONE,
      salesPrice: QUANTITY_ONE,
      salesPriceFormatted: "",
    });
  };

  const handleAddToCart = () => {
    addToCart(selectedOptionEan);
    setActiveError(true);
    setTimeout(() => {
      setActiveError(false);
    }, CLEAR_ERROR_TIMEOUT_MS);
  };

  const handleSizeSelect = (ean: string) => {
    setSelectedOptionEan(ean);

    if (buyAfterSizeSelect) {
      addToCart(ean);
      setBuyAfterSizeSelect(false);
    }
  };

  const handlePrepareToBuy = () => {
    setBuyAfterSizeSelect(true);
    toggleIsSizeSelectDrawerOpen();
  };

  const isLoading = isAddingToCart && isNotNullOrUndefined(selectedOptionEan);
  const selectedSizeText =
    sizeOptions.find(({ ean }) => ean === selectedOptionEan)?.size ?? "";
  const displayText =
    selectedOptionEan === undefined
      ? t("product.details.select.size")
      : selectedSizeText;
  const isSizeSelected = selectedOptionEan !== undefined;

  return (
    <OutsideClickHandler
      onOutsideClick={() => setSelectedOptionEan(undefined)}
      useCapture={true}
    >
      <ButtonWrapper>
        <SizeSelectButton
          displayText={displayText}
          onClick={toggleIsSizeSelectDrawerOpen}
          open={isSizeSelectDrawerOpen}
          size={"small"}
        />
        <XxlButton
          size="small"
          variant="secondary"
          icon="Plus"
          onClick={isSizeSelected ? handleAddToCart : handlePrepareToBuy}
          disabled={isLoading}
          loading={isLoading}
        >
          {t("product.details.add.to.cart.label")}
        </XxlButton>

        {activeError && <AddToCartWarning />}
      </ButtonWrapper>
      <AccessoriesSizeSelectDrawer
        isOpen={isSizeSelectDrawerOpen}
        onClose={toggleIsSizeSelectDrawerOpen}
        sizeOptions={sizeOptions}
        onSizeSelect={handleSizeSelect}
      />
    </OutsideClickHandler>
  );
};
