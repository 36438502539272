import { useSharedData } from "@/react-app/contexts/SharedData";
import { PriceWithLabels } from "@/react-components/Product/PriceWithLabels/PriceWithLabels";
import { DEFAULT_GRID_VALUE } from "@/react-components/Product/constants";
import { Stack, useMediaQuery } from "@mui/material";
import { isNotNull, isNullOrUndefined } from "@xxl/common-utils";
import { CaretUp } from "@xxl/icons";
import { useKeenSlider } from "keen-slider/react";
import { useState } from "react";
import { AccessoriesAside } from "react-app/src/components/AccessoriesAside/AccessoriesAside";
import { Slider } from "react-app/src/components/Common/Slider";
import { Product } from "react-app/src/components/Product/Product";
import {
  getPriceData,
  toProductCardData,
} from "react-app/src/components/Product/product-helper";
import type { ProductData } from "react-app/src/components/Product/types";
import { useTranslations } from "react-app/src/contexts/Translations/TranslationsContext";
import { xxlTheme } from "react-app/src/styles/xxl-theme";
import { laptopMediaQuery } from "react-app/src/utils/xxl-screen";
import { NextImage } from "../../../../components/common/NextImage/NextImage";
import {
  Arrow,
  Header,
  ProductWrapper,
  SliderWrapper,
} from "./Accessories.styles";
import { SizeSelectContainer } from "./SizeSelectContainer";
import type { SizeOption } from "./types";
import { log } from "@xxl/logging-utils";

const IMAGE_ASIDE_SIZE_MOBILE = 110;
const IMAGE_ASIDE_SIZE_DESKTOP = 218;

type Props = {
  carouselProducts: React.ReactElement[];
  primaryImage: string;
};

export const Accessories = ({ carouselProducts, primaryImage }: Props) => {
  const { t } = useTranslations();
  const {
    featureToggles: { toggle_products_as_package_quantity },
    siteDefaultLanguage,
  } = useSharedData().data;
  const [currentSlide, setCurrentSlide] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>({
    initial: 0,
    slides: {
      perView: 4,
      spacing: 4,
    },
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel);
    },
    created() {
      setLoaded(true);
    },
  });
  const isLaptopSize = useMediaQuery(laptopMediaQuery);

  const products = carouselProducts
    .map((product, index) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      const accessoryProduct = product.props.children.props
        .product as ProductData;
      const { priceDisplay, productType } = accessoryProduct;

      if (isNullOrUndefined(priceDisplay)) {
        log.error(
          `Product with code ${
            accessoryProduct.code ?? "undefined"
          } is missing a price display.`
        );
        return null;
      }

      const priceData = getPriceData({
        version: 1,
        priceDisplay,
        productType: productType ?? "NORMAL",
        showPackagePrice: false,
        siteDefaultLanguage,
        toggleProductsAsPackageQuantity: toggle_products_as_package_quantity,
        units: accessoryProduct.units,
      });

      if (priceData === null) {
        log.error(
          `Product with code ${
            accessoryProduct.code ?? "undefined"
          } is missing price data.`
        );
        return null;
      }

      const { colorTheme, highlightedLabel, priceSplash } = priceData;

      return (
        <ProductWrapper key={accessoryProduct.name}>
          <Product
            isSliderProductList={true}
            isHoverable={false}
            product={toProductCardData(
              accessoryProduct,
              accessoryProduct.productType ?? "NORMAL"
            )}
            productMetaData={{
              list: "carousel",
              position: accessoryProduct.pk ?? 0,
              pageType: "pdp",
            }}
            selectedColumnsNumber={DEFAULT_GRID_VALUE}
            selectedFilters={[]}
            PriceComponent={
              <PriceWithLabels
                version={1}
                priceDisplay={accessoryProduct.priceDisplay}
                productType={accessoryProduct.productType ?? "NORMAL"}
                selectedColumnsNumber={DEFAULT_GRID_VALUE}
                t={t}
              />
            }
            priceSplash={priceSplash}
            highlightedLabel={highlightedLabel}
            colorTheme={colorTheme}
            positionInList={index}
          />
          <Stack
            spacing={xxlTheme.spaces.mini}
            marginTop={xxlTheme.spaces.mini}
          >
            <SizeSelectContainer
              sizeOptions={
                (accessoryProduct.sizeOptions ?? []) as unknown as SizeOption[]
              }
              product={accessoryProduct}
            />
          </Stack>
        </ProductWrapper>
      );
    })
    .filter(isNotNull);

  if (!isLaptopSize) {
    return (
      <section>
        <Stack spacing={xxlTheme.spaces.mini}>
          <Header>{t("bundle.accessories")}</Header>
          <div>
            <AccessoriesAside
              text={t("product.details.accessories.carousel.heading")}
              isMobile={true}
            >
              <NextImage
                width={IMAGE_ASIDE_SIZE_MOBILE}
                height={IMAGE_ASIDE_SIZE_MOBILE}
                src={primaryImage}
                alt={t("product.image")}
              />
            </AccessoriesAside>
          </div>
          <div>
            <Slider
              slidesConfig={{
                perView: products.length === 1 ? 1 : 2,
                spacing: 7,
              }}
              items={products}
            />
          </div>
        </Stack>
      </section>
    );
  }

  const withSlider = products.length > 4;

  const handleLeftClick = () => instanceRef.current?.prev();
  const handleRightClick = () => instanceRef.current?.next();

  return (
    <section style={{ overflow: "hidden" }}>
      <Header>{t("bundle.accessories")}</Header>
      <Stack direction={"row"}>
        <AccessoriesAside
          text={t("product.details.accessories.carousel.heading")}
        >
          <NextImage
            width={IMAGE_ASIDE_SIZE_DESKTOP}
            height={IMAGE_ASIDE_SIZE_DESKTOP}
            src={primaryImage}
            alt={t("product.image")}
          />
        </AccessoriesAside>
        {withSlider ? (
          <SliderWrapper>
            <div ref={sliderRef} className="keen-slider">
              {products.map((child) => (
                <div key={child.key} className="keen-slider__slide">
                  {child}
                </div>
              ))}
            </div>
            {loaded && instanceRef.current !== null && (
              <>
                <Arrow
                  direction="left"
                  onClick={handleLeftClick}
                  disabled={currentSlide === 0}
                >
                  <CaretUp fontSize={18} />
                </Arrow>
                <Arrow
                  direction="right"
                  onClick={handleRightClick}
                  disabled={
                    currentSlide ===
                    instanceRef.current.track.details.slides.length - 1
                  }
                >
                  <CaretUp fontSize={18} />
                </Arrow>
              </>
            )}
          </SliderWrapper>
        ) : (
          <Stack spacing={xxlTheme.spaces.mini} direction={"row"}>
            {products.map((child) => (
              <div key={child.key}>{child}</div>
            ))}
          </Stack>
        )}
      </Stack>
    </section>
  );
};
