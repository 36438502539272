import styled from "@emotion/styled/macro";
import { resolutionSizes } from "react-app/src/config";
import { xxlTheme } from "react-app/src/styles/xxl-theme";

const { colors, spaces } = xxlTheme;

export const ProductWrapper = styled.div`
  max-width: 216px;

  @media (max-width: ${resolutionSizes.laptop}px) {
    max-width: 100%;
  }
`;

export const SliderWrapper = styled.div`
  width: 100%;
  max-width: calc(214px * 4);
  position: relative;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spaces.miniMicro};
`;

export const Arrow = styled.button<{
  direction: "left" | "right";
}>`
  cursor: pointer;
  position: relative;
  outline: none;
  border: none;
  z-index: 1;
  background-color: ${colors.xxlWebBlack};
  padding: ${spaces.line} ${spaces.micro};
  color: ${colors.xxlWhite};
  transform: ${({ direction }) =>
    direction === "right" ? "rotate(90deg)" : "rotate(270deg)"};
  ${({ direction }) =>
    direction === "left" && `top: calc(-616px / 2) ;left: -32px;`}
  ${({ direction }) =>
    direction === "right" &&
    `top: calc(-616px / 2) ;right: calc(214px * -4 + 20px)`}
`;

export const Header = styled.h2`
  margin-top: 0;
`;
