import { isNotNull, isNotNullOrUndefined } from "@xxl/common-utils";
import type {
  ProductData,
  RecommendationsParameters,
} from "@xxl/recommendations-api";
import { RecommendationsParametersStrategyEnum as Strategy } from "@xxl/recommendations-api";
import { useEffect, useState } from "react";
import { SkeletonWrapper } from "react-app/src/components/Common";
import { Slider } from "react-app/src/components/Common/Slider";
import { createProductItems } from "react-app/src/components/PersonalizedProductList";
import {
  ListStyles,
  getTitle,
} from "react-app/src/components/PersonalizedProductList/personalized-product-list-helper";
import {
  cardHeightMobile,
  nonHoverableCardHeight,
} from "react-app/src/components/Product/Product.styled";
import { useApiClients } from "react-app/src/contexts/ApiClients";
import { useSharedData } from "react-app/src/contexts/SharedData";
import { useTracking } from "react-app/src/contexts/Tracking";
import { useTranslations } from "react-app/src/contexts/Translations/TranslationsContext";
import { useXxlMediaQuery } from "react-app/src/hooks/useXxlMediaQuery";
import { withErrorBoundary } from "react-app/src/utils/WithErrorBoundary/with-error-boundary";
import { legacySiteUidToSiteUid } from "react-app/src/utils/xxl-shared-data";
import { useInView } from "react-intersection-observer";
import { ProductListContainer } from "../../ProductDetailsPage/ProductDetailsPage.styles";
import { Accessories } from "./Accessories/Accessories";
import {
  getAccessoriesProps,
  getColors,
  useUniqueProps,
  type HandlerProps,
} from "./Handler.helper";
import {
  HeaderWithHeadingOffset,
  InnerWrapper,
  Wrapper,
} from "./Handler.styled";
import { log } from "@xxl/logging-utils";
import { getXXLCookie } from "@/react-utils/Cookie";
import { useStateValue } from "cotton-box-react";
import { useSessionSource } from "@/react-app/contexts/Session";

const _Handler = (props: HandlerProps) => {
  const {
    name: key,
    productsCount,
    strategy,
    styleId,
    fetchDataOnLoad = false,
    type = "SELF_CONTAINED",
    paddingTop,
    sliderStyles,
    colorTheme,
  } = props;
  const { recommendationsApi } = useApiClients();
  const trackers = useTracking();
  const isLaptopSize = useXxlMediaQuery("LaptopMediaQuery");
  const {
    featureToggles: { toggle_products_as_package_quantity },
    pageType,
    siteDefaultLanguage,
    siteUid,
  } = useSharedData().data;
  const isLoggedIn = useStateValue(useSessionSource);
  const [recommendedProducts, setRecommendedProducts] = useState<
    ProductData[] | null
  >(null);
  const [carouselProducts, setCarouselProducts] = useState<
    (JSX.Element | null)[] | null
  >(null);
  const uniqueProps = useUniqueProps(props);
  const { t } = useTranslations();
  const [shouldFetchProducts, setShouldFetchProducts] = useState(false);
  const isLaptop = useXxlMediaQuery("LaptopMediaQuery");
  const typesWithCustomContent: Strategy[] = [Strategy.accessories];
  const isCustomContentType = typesWithCustomContent.includes(strategy);
  const cardHeight = `${
    isLaptopSize ? nonHoverableCardHeight : cardHeightMobile
  }px`;

  const { ref: inViewRef } = useInView({
    triggerOnce: true,
    threshold: 0.01,
    onChange: (inView) => setShouldFetchProducts(inView),
    rootMargin: "300px 0px",
  });
  const { background, heading } = getColors(strategy, colorTheme);

  useEffect(() => {
    setShouldFetchProducts(fetchDataOnLoad);
  }, [fetchDataOnLoad]);

  useEffect(() => {
    setShouldFetchProducts(true);
  }, [isLoggedIn]);

  useEffect(() => {
    if (uniqueProps === null || !shouldFetchProducts) {
      return;
    }

    const getRecommendations = async (
      parameters: RecommendationsParameters
    ) => {
      try {
        const cookie = await getXXLCookie();
        parameters.userGroupIds = cookie?.userGroups;
        const response = await recommendationsApi.getRecommendations(
          legacySiteUidToSiteUid(siteUid),
          [parameters]
        );

        const { productList } = response.data.response[0];
        if (productList === undefined) {
          throw Error("Recommendations returned an undefined product list.");
        }

        setRecommendedProducts(productList);
      } catch (error) {
        log.error(error);
        setRecommendedProducts(null);
      }
    };

    const {
      brands,
      campaigns,
      categories,
      includedProducts,
      nrOfDays,
      price,
      sessionId,
    } = uniqueProps;
    const params: RecommendationsParameters = {
      ...(includedProducts !== null && { includedProducts }),
      key,
      numResults: productsCount,
      ...(sessionId !== null && { sessionId }),
      ...(price !== null && { price }),
      strategy,
      styleId,
    };

    params.filters = {
      ...(isNotNullOrUndefined(brands) && {
        brands,
      }),
      ...(isNotNullOrUndefined(campaigns) && {
        campaigns,
      }),
      ...(isNotNullOrUndefined(categories) && {
        categories,
      }),
      ...(isNotNullOrUndefined(nrOfDays) && {
        days: nrOfDays,
      }),
    };

    void getRecommendations(params);
    setShouldFetchProducts(false);
  }, [
    key,
    productsCount,
    props,
    recommendationsApi,
    shouldFetchProducts,
    siteUid,
    strategy,
    styleId,
    uniqueProps,
  ]);

  useEffect(() => {
    if (recommendedProducts === null) {
      return;
    }

    setCarouselProducts(
      createProductItems({
        prioritizeImage: false,
        listName: strategy,
        listStyle: ListStyles.HORIZONTAL,
        productsCount,
        pageType,
        trackers,
        siteDefaultLanguage,
        toggleProductsAsPackageQuantity: toggle_products_as_package_quantity,
        t,
        products: recommendedProducts,
      })
    );
  }, [
    pageType,
    productsCount,
    recommendedProducts,
    siteDefaultLanguage,
    strategy,
    toggle_products_as_package_quantity,
    trackers,
    t,
  ]);

  if (carouselProducts !== null && carouselProducts.length === 0) {
    return null;
  }

  if (isCustomContentType && carouselProducts === null) {
    return null;
  }

  if (type === "SUB_COMPONENT") {
    return (
      <Wrapper color={background} ref={inViewRef} style={{ paddingTop }}>
        <InnerWrapper hasPadding={false}>
          <div style={{ minHeight: cardHeight }}>
            <SkeletonWrapper isLoading={carouselProducts === null}>
              <div
                style={{
                  ...{ minHeight: cardHeight, width: "100%" },
                  ...sliderStyles,
                }}
              >
                <Slider
                  items={carouselProducts?.filter(isNotNull) ?? []}
                  navigation={isLaptop ? "ARROWS" : "DOTS"}
                  slidesConfig={{
                    perView: isLaptop ? 6 : 2,
                    spacing: 6,
                  }}
                />
              </div>
            </SkeletonWrapper>
          </div>
        </InnerWrapper>
      </Wrapper>
    );
  }

  const filteredProducts = carouselProducts?.filter(isNotNull) ?? [];

  return (
    <ProductListContainer>
      <Wrapper color={background} ref={inViewRef} style={{ paddingTop }}>
        <InnerWrapper hasPadding={!isCustomContentType}>
          {isCustomContentType ? null : (
            <HeaderWithHeadingOffset
              style={{ color: heading, marginTop: 0 }}
              id={strategy}
            >
              {getTitle(strategy, t)}
            </HeaderWithHeadingOffset>
          )}
          <div style={{ minHeight: cardHeight }}>
            <SkeletonWrapper isLoading={carouselProducts === null}>
              {strategy === Strategy.accessories &&
              filteredProducts.length > 0 ? (
                <Accessories
                  carouselProducts={filteredProducts}
                  primaryImage={getAccessoriesProps(props).productImage}
                />
              ) : (
                <div style={{ minHeight: cardHeight, width: "100%" }}>
                  <Slider
                    items={filteredProducts}
                    navigation={isLaptop ? "ARROWS" : "DOTS"}
                    slidesConfig={{
                      perView: isLaptop ? 6 : 2,
                      spacing: 6,
                    }}
                  />
                </div>
              )}
            </SkeletonWrapper>
          </div>
        </InnerWrapper>
      </Wrapper>
    </ProductListContainer>
  );
};

export const Handler = withErrorBoundary(_Handler);
